let host = '';//'https://outletsmallapi.bxncc.cc'; //'http://192.168.33.128:8000';
let imageHost = 'https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com';
const api = {
    host: host,
    imageHost: imageHost,
    getUserInfo: host + '/consumer/v1/wx/cp/getUserInfo',

    getIncentiveRedPacketActionList: host + '/consumer/incentive/getIncentiveRedPacketActionList',
    getIncentiveRedPacketWalletAccount: host + '/consumer/incentive/getIncentiveRedPacketWalletAccount',
    getIncentiveRedPacketRecordList: host + '/consumer/incentive/getIncentiveRedPacketRecordList',
    getIncentiveRedpacketRecordById: host + '/consumer/incentive/getIncentiveRedPacketRecordById',
    receiveIncentiveRedPacket: host + '/consumer/incentive/receiveIncentiveRedPacket',
}

export default api;